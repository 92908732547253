.faq_container {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.text_box {
  border: 1px solid #999999;
  min-height: 50px;
  word-break: keep-all;
}

