.Notice_container {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.category_box {
  text-align: start;
  margin-bottom: 10px;
  .category_btn {
    display: inline-block;
    margin-right: 10px;
    background-color: #295dee;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

.NoticeMain {
  width: 100%;
  border-style: solid;
  border-width: 3px 0px 0px 0px;
  border-color: #5076dd;
}
.Main-Header {
  width: 100%;
  background-color: #f5f8ff;
  padding: 10px 0;
  height: 50px;
}
.Main-body {
  width: 100%;
  height: 100%;
}

.write_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  align-self: center;
  justify-content: flex-start;
  margin: 20px 0;
}
.write_button {
  justify-content: center;
  align-self: center;
  width: 10%;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #295dee;
  color: #fff;
  cursor: pointer;
}



.modal_text_area {
  padding: 10px;
  text-align: start;
}
.modal_select_box {
  margin-bottom: 10px;
  .select_title {
    font-size: 20px;
    display: inline-block;
    width: 100px;
    vertical-align: top;
  }
  .select_input {
    display: inline-block;
  }
}
.modal_text_box {
  margin-bottom: 10px;
  .text_title {
    font-size: 20px;
    display: inline-block;
    width: 100px;
    vertical-align: top;
  }
  .text_input {
    display: inline-block;
    width: calc(100% - 100px);
    .modal_input {
      width: 100%;
    }
    .modal_textarea {
      resize: none;
      width: 100%;
      height: 220px;
    }
  }
}

.success_btn {
  width: 100%;
  background-color: #295dee;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
}


.smallMenu_Container {
  display: flex;
  width: 100%;
  border-style: solid;
  border-width: 0px 0px 5px 0px;
  border-color: #a7a7a7;
  flex-direction: row;
  justify-content: space-around;
  height: 10%;
  align-self: center;

  .Options {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    transition: 0.5s;
  }
}
