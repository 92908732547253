.Point-Main {
  display: flex;
  width: 100%;
  border-style: solid;
  border-width: 3px 0px 0px 0px;
  border-color: #5076dd;
  flex-direction: column;

  height: 250;
  align-self: center;
}
.Point-Table-Col {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  padding: 7px 0;
  justify-content: space-around;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}
.Point-Input {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 90%;
  align-self: center;

  justify-content: space-around;
}
.Point-Button {
  justify-content: center;
  align-self: center;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background-color: #5076dd;
  color: #fff;
  cursor: pointer;
}
.Content-Text-Bold {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 0;
}
.Give-Point-Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  align-self: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.Point-User-Button {
  justify-content: center;
  align-self: center;
  width: 10%;
  height: 90%;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #295dee;
  color: #fff;
  cursor: pointer;
  margin: 5px;
}
.Point-All-User-Button {
  justify-content: center;
  align-self: center;
  width: 10%;
  height: 90%;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #5076dd;
  color: #fff;
  cursor: pointer;
}
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal-Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  
  width: 50%;
  max-width: 50%;
  text-align: center;
}
.Modal-Content-Header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  align-self: center;
  justify-content: flex-start;
  justify-content: space-between;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}
.Modal-Content-Header > h2 {
  border-style: solid;
  border-width: 0px 0px 0px 3px;
  border-color: #5076dd;
  padding-left: 25px;
}
.Modal-Content-Body {
  display: flex;
  margin-top: 20px;
  flex-direction: row;

  width: 100%;
  height: 250px;
  align-self: center;
  justify-content: flex-start;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
.Modal-Content-Body > div > p {
  margin: 5px;
  font-size: 18px;
  font-weight: 600;
}

.Modal-Content p {
  margin-bottom: 20px;
}
.Modal-Content-Bottom {

  width: 100%;
  height: 10%;
  align-self: center;
  justify-content: flex-end;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
.modal_bottom_box {
  display: inline-block;
  text-align: left;
  width: 50%;
}
.modal_title {
  display: inline-block;
  margin-right: 20px;
}
.modal_input {
  display: inline-block;
  width: calc(100% - 100px);
}
.PointAD-Close-BTN {
  margin-bottom: 10px;
  justify-content: center;
  padding: 5px 20px;
  background-color: #295dee;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.Modal-Content-BTN {
  margin-top: 10px;
  width: 100%;
  padding: 5px 20px;
  justify-content: center;
  background-color: #295dee;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
